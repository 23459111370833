import { Injectable } from '@angular/core';
import { HttpApiService } from '@core/services/http-api/http-api.service';
import { Animal } from '../../../../models/animal.model';
import { Observable } from 'rxjs';
import { AssistantEvent } from '../../../../models/chat.model';
import { environment } from '../../../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class BotService {
  constructor(private httpApiService: HttpApiService) {}

  private readonly apiUrl = environment.API_URL;

  getThreadId(): Observable<string> {
    return this.httpApiService.postWithParams<string>('api/back_office/bot/thread', undefined, undefined, { responseType: 'text' });
  }

  subscribe(threadId: string) {
    return this.httpApiService.getAsStream<AssistantEvent>(`${this.apiUrl}/api/back_office/bot/stream/${threadId}`);
  }

  ask(threadId: string, question: string, animal: Animal | null, idStructure?: string) {
    const chatRequest = {
      question: question,
      animal: animal,
      idStructure,
    };

    return this.httpApiService.post<void>(`api/back_office/bot/message/${threadId}`, chatRequest);
  }
}
