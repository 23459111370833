import { Injectable } from '@angular/core';
import { CreationHorsRdvPayloadType, FindHorsRdvRequest, HorsRdv, HorsRdvStep } from 'app/models/rdv.model';
import { map, Observable } from 'rxjs';
import { HttpApiService } from '../http-api/http-api.service';
import { Page } from 'app/models/page.model';
import { environment } from '../../../../../environments/environment';
import { isEmpty } from 'lodash';
import { User, UserRole } from '../../../../models/user.model';

@Injectable({
  providedIn: 'root',
})
export class HorsRdvService {
  private readonly apiUrl = environment.API_URL;

  constructor(private readonly httpApiService: HttpApiService) {}

  static getStepsSelectionnables(currentStep: HorsRdvStep, user: User): HorsRdvStep[] {
    const stepsToReturn = [currentStep];
    switch (currentStep) {
      case HorsRdvStep.ANNULE:
      case HorsRdvStep.CLOTURE:
        break;
      case HorsRdvStep.INIT:
        user.role === UserRole.ROLE_VETERINAIRE ? stepsToReturn.push(HorsRdvStep.ANNULE) : stepsToReturn.push(HorsRdvStep.ANNULE, HorsRdvStep.CLOTURE);
        break;
      case HorsRdvStep.PAIEMENT_OK:
      case HorsRdvStep.PAIEMENT_EN_COURS:
        if ([UserRole.ROLE_BACKOFFICE, UserRole.ROLE_ADMIN].includes(user.role)) {
          stepsToReturn.push(HorsRdvStep.ANNULE, HorsRdvStep.CLOTURE);
        }
        break;
    }

    return stepsToReturn;
  }

  findAllRdv(findRdvRequest: FindHorsRdvRequest): Observable<Page<HorsRdv>> {
    if (isEmpty(findRdvRequest)) {
      throw new Error('données vides');
    }

    return this.httpApiService.post<Page<HorsRdv>>('api/back_office/horsRdv/find', findRdvRequest).pipe(map(data => data));
  }

  updateStep(idRdv: string, rdvStep: HorsRdvStep): Observable<void> {
    return this.httpApiService.post<void>(`api/back_office/horsRdv/${idRdv}/updateRdvStep/${rdvStep}`);
  }

  creationHorsRdv(rdvPayload: CreationHorsRdvPayloadType): Observable<HorsRdv> {
    return this.httpApiService.post<HorsRdv>(`api/back_office/horsRdv/`, rdvPayload);
  }

  updateHorsRdv(rdv: HorsRdv): Observable<HorsRdv> {
    return this.httpApiService.post<HorsRdv>(`api/back_office/horsRdv/`, rdv);
  }

  findById(id: string) {
    return this.httpApiService.get<HorsRdv>(`api/back_office/horsRdv/${id}`);
  }

  isHorsRdvEditable(horsRdv: HorsRdv, user: User) {
    if (!user) {
      return false;
    }

    if (user.role === UserRole.ROLE_ADMIN) {
      return true;
    }

    if (user.role === UserRole.ROLE_BACKOFFICE || (user.role === UserRole.ROLE_VETERINAIRE && horsRdv.responsable?.id === user.id)) {
      return ![HorsRdvStep.ANNULE, HorsRdvStep.CLOTURE].includes(horsRdv.step);
    }

    return false;
  }
}
