import { inject, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PageNotFoundComponent } from '@core/components/page-not-found/page-not-found.component';
import { clientGuard, dashboardGuard, isUserAuthenticatedGuard, isUserNotAuthenticatedGuard } from '@core/guards/authentication.guard';

import { HomePage } from '@core/pages/home/home.page';
import { LoginPage } from '@core/pages/login/login.page';
import { ForgotPasswordPage } from '@core/pages/forgot-password/forgot-password.page';
import { ChangementMotDePassePage } from '@core/pages/changement-mot-de-passe/changement-mot-de-passe.page';
import { Store } from '@ngrx/store';
import { selectUser } from '@core/store/selector/session.selectors';
import { first, tap } from 'rxjs';
import { UserRole } from './models/user.model';
import { AppComponent } from './app.component';
import { NavController } from '@ionic/angular';
import { TarifPage } from './modules/admin/pages/tarifs/tarif.page';
import { TarifActesPage } from './modules/admin/pages/tarifs/actes/tarif-actes.page';
import { TarifProduitsPage } from './modules/admin/pages/tarifs/produits/tarif-produits.page';

const rootGuard = () => {
  const navCtrl = inject(NavController);

  return inject(Store)
    .select(selectUser)
    .pipe(
      first(),
      tap(user => {
        if (user?.role === UserRole.ROLE_VETERINAIRE) {
          navCtrl.navigateForward(['dashboard']);
        } else {
          navCtrl.navigateForward(['planning']);
        }
      }),
    );
};

const routes: Routes = [
  {
    path: '',
    title: 'Accueil',
    canActivate: [isUserAuthenticatedGuard],
    component: HomePage,
    children: [
      {
        path: '',
        component: AppComponent,
        canActivate: [rootGuard],
        pathMatch: 'full',
      },
      {
        path: 'dashboard',
        loadChildren: () => import(`./modules/dashboard/dashboard.module`).then(m => m.DashboardModule),
        title: 'Accueil',
        canActivate: [dashboardGuard],
      },
      {
        path: 'dashboard-veto',
        loadChildren: () => import(`./modules/dashboard-veto/dashboard-veto.module`).then(m => m.DashboardVetoModule),
        title: 'Dashboard Véto',
        data: {
          fromAdmin: false,
        },
        // canActivate: [dashboardGuard],
      },
      {
        path: 'planning',
        loadChildren: () => import(`./modules/planning/planning.module`).then(m => m.PlanningPageModule),
        title: 'Planning',
        // canActivate: [dashboardGuard],
      },
      {
        path: 'chat',
        loadChildren: () => import(`./modules/chat/chat.module`).then(m => m.ChatModule),
        title: 'Discussions',
      },
      {
        path: 'rendezvous',
        loadChildren: () => import(`./modules/rdv/rdv.module`).then(m => m.RdvModule),
        title: 'Mon rendez-vous',
        canActivate: [dashboardGuard],
      },
      {
        path: 'fiche-client',
        loadChildren: () => import(`./modules/fiche-client/fiche-client.module`).then(m => m.FicheClientModule),
        title: 'Fiche client',
        canActivate: [clientGuard],
      },
      {
        path: 'zone-admin',
        loadChildren: () => import(`./modules/admin/admin.module`).then(m => m.AdminModule),
        title: 'Gestion',
      },
      {
        path: 'tarifs',
        component: TarifPage,
        title: 'Tarifs',
      },
      {
        path: 'tarifs/actes',
        component: TarifActesPage,
      },
      {
        path: 'tarifs/produits',
        component: TarifProduitsPage,
      },
      {
        path: 'profil',
        title: 'Mon profil',
        loadChildren: () => import(`./modules/profil/profil.module`).then(m => m.ProfilModule),
      },
      {
        path: 'messagerie',
        title: 'Messagerie',
        loadChildren: () => import(`./modules/messagerie-interne/messagerie-interne.module`).then(m => m.MessagerieInterneModule),
      },
    ],
  },
  {
    path: 'login',
    component: LoginPage,
    title: 'Login',
    canActivate: [isUserNotAuthenticatedGuard],
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordPage,
    title: 'Mot de passe oublié',
    canActivate: [isUserNotAuthenticatedGuard],
  },
  {
    path: 'changement-mot-de-passe',
    component: ChangementMotDePassePage,
    title: 'Changement de mot de passe',
    canActivate: [isUserNotAuthenticatedGuard],
  },
  {
    path: '**',
    canActivate: [isUserAuthenticatedGuard],
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
