import { Injectable } from '@angular/core';
import { HttpApiService } from '@core/services/http-api/http-api.service';
import { Observable } from 'rxjs';
import { FindRdvRequest, StatRdvDomicile, StructureVeterinaire } from '../../../../models/rdv.model';
import { Commune, CommuneDesservie, VetophonieUser } from '../../../../models/admin.model';

@Injectable({
  providedIn: 'root',
})
export class StructureService {
  constructor(private readonly httpApiService: HttpApiService) {}

  public getStructure(id: string): Observable<StructureVeterinaire> {
    return this.httpApiService.get(`api/back_office/structure/${id}`);
  }

  public getSites(nomStructure: string): Observable<
    {
      id: number;
      code: string;
      label: string;
    }[]
  > {
    return this.httpApiService.get(`api/back_office/structure/evoliz-sites/${nomStructure}`);
  }

  createStructure(
    structureWithVetophonieUser: {
      structure: StructureVeterinaire;
      vetophonieUser: VetophonieUser;
    },
    idStructureToCopyActesFrom?: string,
  ): Observable<StructureVeterinaire> {
    if (idStructureToCopyActesFrom) {
      return this.httpApiService.post(`api/back_office/structure/${idStructureToCopyActesFrom}`, structureWithVetophonieUser);
    } else {
      return this.httpApiService.post(`api/back_office/structure/`, structureWithVetophonieUser);
    }
  }

  isOpen(codeStructure: string) {
    return this.httpApiService.get<boolean>(`api/is-open/code/${codeStructure}`);
  }

  updateStructure(structure: StructureVeterinaire): Observable<StructureVeterinaire> {
    return this.httpApiService.post<StructureVeterinaire>('api/back_office/structure/update', structure);
  }

  updateVetophonieUser(user: VetophonieUser): Observable<VetophonieUser> {
    return this.httpApiService.post<VetophonieUser>('api/back_office/structure/updateVetophonieUser', user);
  }

  updateCommunes(id: string, replace: boolean, communes: Commune[]) {
    return this.httpApiService.post<void>(`api/back_office/structure/${id}/${replace}/updateCommunes`, communes);
  }

  getVetophonieUser(idStructure: string): Observable<VetophonieUser> {
    return this.httpApiService.get(`api/back_office/structure/${idStructure}/vetophonieUser`);
  }

  copyActesFrom(id: string, idStructureToCopy: string) {
    return this.httpApiService.post<void>(`api/back_office/structure/${id}/copyActesFrom/${idStructureToCopy}`);
  }

  getCommunesDesserviesStructure(idStructure: string) {
    return this.httpApiService.get<CommuneDesservie[]>(`api/back_office/structure/${idStructure}/communesDesservies`);
  }

  exportCommunes(idStructure: string): Observable<Blob> {
    return this.httpApiService.get(`api/back_office/structure/${idStructure}/exportCommunes`, undefined, { responseType: 'arraybuffer' });
  }

  exportStats(request: FindRdvRequest): Observable<Blob> {
    return this.httpApiService.postWithParams(`api/back_office/export/rdv-domicile`, request, undefined, { responseType: 'arraybuffer' });
  }

  getStatRdvDomicile(request: FindRdvRequest): Observable<StatRdvDomicile> {
    return this.httpApiService.post<StatRdvDomicile>('api/back_office/export/stats-rdv-domicile', request);
  }
}
