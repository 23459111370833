<ion-header>
  <ion-toolbar color="blue100" class="ion-text-center">
    <ion-buttons slot="start">
      <ion-button color="blue600" (click)="cancel()">Fermer</ion-button>
    </ion-buttons>
    <ion-title color="grey900">{{ title }}</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content color="blue100" class="envoi-facture">
  <ion-grid fixed>
    <ion-row>
      <ion-col>
        <ion-button class="btn primary-btn" expand="block" color="secondary" (click)="download()">
          <ion-icon name="download-outline"></ion-icon>
          <ion-label>Télécharger la note d'honoraire</ion-label>
        </ion-button>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-item lines="none" shape="round">
          <ion-label>Email :</ion-label>
          <ion-input (ionChange)="setMail($event)" [value]="email"></ion-input>
        </ion-item>
        <ion-button class="btn secondary-btn" expand="block" color="secondary" [disabled]="loadingSend" (click)="send()">
          <ion-spinner color="blue600" *ngIf="loadingSend"></ion-spinner>
          <ion-icon name="send-outline" *ngIf="!loadingSend"></ion-icon>

          <ion-label>Envoyer la note d'honoraire</ion-label>
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
