<ng-container *ngIf="presta.compteRendu?.files?.length">
  <ion-list class="file-list">
    <ion-item *ngFor="let file of presta.compteRendu?.files; let i = index; let last = last" [lines]="last ? '' : 'none'">
      <ng-container [ngSwitch]="file.type">
        <ion-icon slot="start" name="camera-outline" *ngSwitchCase="FileCRTypeEnum.PHOTO" title="Photo"></ion-icon>
        <ion-icon slot="start" name="chatbox-ellipses-outline" *ngSwitchCase="FileCRTypeEnum.RETOUR_REFERE" title="Retour référé"></ion-icon>
        <ion-icon slot="start" name="document-text-outline" *ngSwitchCase="FileCRTypeEnum.CERTIFICAT" title="Certificat"></ion-icon>
        <ion-icon slot="start" name="flask-outline" *ngSwitchCase="FileCRTypeEnum.RESULTAT_ANALYSE" title="Résultat d'analyse"></ion-icon>
        <ion-icon slot="start" name="document-text-outline" *ngSwitchCase="FileCRTypeEnum.INCONNU" title="Inconnu"></ion-icon>
      </ng-container>
      <ion-label class="ion-text-wrap">{{ file.fileName }}</ion-label>
      <ion-button (click)="downloadFile(file)" fill="outline" color="blue400" slot="end" *ngIf="file.mimeType !== 'application/pdf'">
        <ion-icon name="download-outline" color="blue600"></ion-icon>
      </ion-button>
      <ion-button (click)="viewFile(file)" fill="outline" color="blue400" slot="end" *ngIf="file.mimeType === 'application/pdf'">
        <ion-icon name="eye-outline" color="blue600"></ion-icon>
      </ion-button>
      <ion-button
        (click)="deleteFile(file.id!)"
        fill="outline"
        color="suppr"
        slot="end"
        *ngIf="user?.role === UserRole.ROLE_ADMIN || file.auteur?.id === user?.id"
      >
        <ion-icon name="close-outline" color="wait"></ion-icon>
      </ion-button>
    </ion-item>
  </ion-list>
</ng-container>
<ion-item lines="none" *ngIf="!presta.compteRendu?.isPdf && presta.compteRendu?.textCr">
  <ion-text class="medium" [innerHTML]="presta.compteRendu?.textCr"></ion-text>
</ion-item>

<div class="btn-container" *ngIf="presta.factures?.length || presta.ordonnances?.length || presta.compteRendu?.isPdf || presta.compteRendu?.uriPiecesjointes">
  <ion-button size="medium" shape="round" *ngIf="presta.compteRendu?.isPdf" (click)="viewCr(presta.compteRendu!.id!)">
    <ion-icon slot="start" name="reader-outline"></ion-icon>
    Compte-rendu
  </ion-button>
  <ion-button size="medium" shape="round" *ngIf="presta.compteRendu?.uriPiecesjointes" (click)="viewPj(presta.compteRendu!.uriPiecesjointes!)">
    <ion-icon slot="start" name="document-attach-outline"></ion-icon>
    Pièces jointes
  </ion-button>
  <ion-button size="medium" shape="round" *ngFor="let facture of presta.factures | orderBy: 'idx'; let idx = index" (click)="viewFacture(facture)">
    Note n°{{ idx + 1 }} : {{ facture.montant }}€&nbsp;
    <ion-button slot="end" color="secondary" shape="round" (click)="$event.stopImmediatePropagation(); sendFacture(facture)">
      <ion-icon slot="icon-only" name="share-social-outline"></ion-icon>
    </ion-button>
  </ion-button>
  <ion-button size="medium" shape="round" *ngFor="let ordo of presta.ordonnances | orderBy: 'numOrdonnance'; let idx = index" (click)="viewOrdo(ordo)">
    Ordonnance n°{{ idx + 1 }}&nbsp;
    <ion-button slot="end" color="secondary" shape="round" (click)="$event.stopImmediatePropagation(); sendOrdo(ordo)">
      <ion-icon slot="icon-only" name="share-social-outline"></ion-icon>
    </ion-button>
  </ion-button>
</div>
