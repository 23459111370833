import { Pipe, PipeTransform } from '@angular/core';
import differenceInCalendarWeeks from 'date-fns/differenceInCalendarWeeks';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';

@Pipe({
  name: 'age',
  pure: true,
})
export class AgePipe implements PipeTransform {
  transform(value?: string): string {
    if (!value) return '';

    const today = new Date();
    const birthDate = new Date(value);

    const totalMonths = (today.getFullYear() - birthDate.getFullYear()) * 12 + today.getMonth() - birthDate.getMonth();

    const years = Math.floor(totalMonths / 12);
    const months = totalMonths % 12;

    if (!years) {
      const weeks = differenceInCalendarWeeks(today, birthDate);
      if (weeks > 0 && weeks <= 4) return `${weeks} ${weeks > 1 ? 'semaines' : 'semaine'}`;
      if (weeks > 4) {
        return `${months} mois`;
      }

      const days = differenceInCalendarDays(today, birthDate);

      return `${days} ${days > 1 ? 'jours' : 'jour'}`;
    }

    const yearStr = `${years} ${years > 1 ? 'ans' : 'an'}`;

    return months ? `${yearStr} ${months} mois` : yearStr;
  }
}
