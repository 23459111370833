import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpApiService } from '@core/services/http-api/http-api.service';

@Injectable({
  providedIn: 'root',
})
export class FactureService {
  constructor(private readonly httpApiService: HttpApiService) {}

  getFacturePDF(id: string): Observable<Blob> {
    return this.httpApiService.get(`api/back_office/facture/${id}/pdf`, undefined, { responseType: 'arraybuffer' });
  }

  markFacturePayee(id: string): Observable<void> {
    return this.httpApiService.post<void>(`api/back_office/facture/${id}/markAsPaid`);
  }

  exportCompta(id: string): Observable<Blob> {
    return this.httpApiService.get(`api/back_office/facture/export/${id}`, undefined, { responseType: 'arraybuffer' });
  }

  exportAllCompta(): Observable<void> {
    return this.httpApiService.get(`api/back_office/facture/export/all`);
  }

  envoyerFactureParMail(idFacture: string, email: string) {
    return this.httpApiService.post<void>(`api/back_office/facture/${idFacture}/envoyerFactureParMail`, email);
  }

  annulerFacture(id: string, idPaiement: string): Observable<void> {
    return this.httpApiService.delete(`api/back_office/facture/${id}/${idPaiement}`);
  }
}
