import { ChangeDetectorRef, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { UserRole } from '../../../../models/user.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BotService } from '@core/services/bot/bot.service';
import { Store } from '@ngrx/store';
import { selectActiveAnimal } from '@core/store/selector/rendezvous.selector';
import { map, take, tap } from 'rxjs/operators';
import { combineLatestWith, debounceTime, firstValueFrom, fromEvent, Subscription, switchMap } from 'rxjs';
import { selectActiveStructure } from '@core/store/selector/admin.selector';
import { CdkDragMove, CdkDragStart } from '@angular/cdk/drag-drop';
import { Browser } from 'leaflet';
import { LogService } from '@core/services/log/log.service';
import { Action, Context, TypeLog } from '../../../../models/log.model';
import mobile = Browser.mobile;

@Component({
  selector: 'app-chatbot',
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.scss'],
})
export class ChatbotComponent implements OnDestroy {
  messageForm: FormGroup;
  @ViewChild('chatDiv') chatDiv?: ElementRef;
  @ViewChild('fabButton', { read: ElementRef }) fabButton?: ElementRef;
  private dragSubscription?: Subscription;
  private lastDragEvent?: CdkDragStart | CdkDragMove;
  showChatContent = false;
  mobile = mobile;

  @ViewChild('messageInput', { read: ElementRef }) set messageInputSet(messageInput: ElementRef) {
    if (messageInput) {
      setTimeout(() => {
        messageInput.nativeElement.setFocus();
      }, 20);
    }
  }

  showChat = false;
  chatMessages: { fromUser: boolean; message: string; id?: string }[] = [
    {
      fromUser: false,
      message: 'Bonjour, je suis Skapiroo. En quoi puis-je vous aider ?',
    },
  ];
  threadId?: string;
  isStreaming = false;
  private streamingMessageId?: string;

  protected readonly UserRole = UserRole;

  constructor(
    private readonly fb: FormBuilder,
    private readonly cd: ChangeDetectorRef,
    private readonly botService: BotService,
    private readonly logService: LogService,
    private readonly store: Store,
  ) {
    this.messageForm = this.fb.group({
      message: ['', [Validators.minLength(1), Validators.maxLength(2024), Validators.required]],
    });
  }

  async showChatFn() {
    this.showChat = !this.showChat;
    if (this.showChat) {
      this.showChatContent = false;
      setTimeout(() => this.updateChatPosition());
    }
    if (this.showChat && !this.threadId) {
      this.threadId = await firstValueFrom(this.botService.getThreadId());
    }
  }

  submit() {
    const question = this.messageForm.get('message')!.value;
    if (!question || question.trim() === '' || !this.threadId || this.isStreaming) {
      return;
    }
    this.isStreaming = true;
    this.chatMessages.unshift({ fromUser: true, message: question });

    this.store
      .select(selectActiveAnimal)
      .pipe(
        take(1),
        combineLatestWith(
          this.store.select(selectActiveStructure).pipe(
            take(1),
            map(s => s?.id),
          ),
        ),
        tap(animalAndStructure =>
          this.logService.log(Context.SKAPIROO, Action.ENVOI, TypeLog.INFO, {
            animal: animalAndStructure[0] || undefined,
            structure: animalAndStructure[1],
            question,
          }),
        ),
        switchMap(animalAndStructure => this.botService.ask(this.threadId!, question, animalAndStructure[0], animalAndStructure[1])),
        switchMap(() => this.botService.subscribe(this.threadId!)),
      )
      .subscribe({
        next: event => {
          try {
            if (event.type === 'done') {
              this.isStreaming = false;
              this.logService.log(Context.SKAPIROO, Action.REPONSE, TypeLog.INFO, this.chatMessages[0].message);

              return;
            }

            const data = event.data;

            switch (event.type) {
              case 'thread.message.created':
              case 'thread.message':
                if (data.role === 'assistant' && !this.chatMessages.some(m => m.id === data.id)) {
                  this.chatMessages.unshift({
                    fromUser: false,
                    message: '',
                    id: data.id,
                  });
                  this.streamingMessageId = data.id;
                  this.cd.markForCheck();
                }
                break;

              case 'thread.message.delta':
                if (data.delta?.content?.[0]?.text?.value) {
                  const messageIndex = this.chatMessages.findIndex(m => m.id === this.streamingMessageId);
                  if (messageIndex !== -1) {
                    this.chatMessages[messageIndex].message += data.delta.content[0].text.value;
                    this.cd.markForCheck();
                  }
                }
                break;

              case 'thread.message.completed':
                this.streamingMessageId = undefined;
                break;

              case 'thread.run.completed':
                this.isStreaming = false;
                this.streamingMessageId = undefined;
                break;
              case 'error':
                this.isStreaming = false;
                this.streamingMessageId = undefined;
                this.chatMessages.push({
                  fromUser: false,
                  message: "Désolé, une erreur s'est produite.",
                });
                break;
            }
          } catch (error) {
            console.error('Error processing event:', error);
          }
        },
        error: () => {
          this.isStreaming = false;
          this.streamingMessageId = undefined;
          this.chatMessages.push({
            fromUser: false,
            message: "Désolé, une erreur s'est produite.",
          });
        },
        complete: () => {
          this.isStreaming = false;
          this.streamingMessageId = undefined;
          this.cd.markForCheck();
        },
      });

    this.messageForm.reset();
  }

  onDragStart(event: CdkDragStart) {
    this.lastDragEvent = event;
    if (!this.dragSubscription) {
      this.dragSubscription = fromEvent(document, 'mousemove')
        .pipe(debounceTime(16))
        .subscribe(() => {
          this.updateChatPosition(event);
        });
    }
  }

  updateChatPosition(event?: CdkDragMove | CdkDragStart) {
    if (!this.showChat || !this.chatDiv) return;

    const chatElement = this.chatDiv.nativeElement;
    const fabElement = this.fabButton!.nativeElement;
    const fabRect = fabElement!.getBoundingClientRect();
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const chatWidth = windowWidth <= 972 ? (windowWidth >= 400 ? 325 : 300) : 650;
    const chatHeight = chatElement.offsetHeight;

    const dragPosition = (event || this.lastDragEvent)?.source.getFreeDragPosition();
    const fabPosition = {
      x: dragPosition ? dragPosition.x + windowWidth - 100 : windowWidth - 100,
      y: dragPosition ? dragPosition.y + windowHeight - 100 : windowHeight - 100,
    };

    const gap = mobile ? (windowWidth >= 400 ? 10 : 5) : 20;
    chatElement.style.left = fabPosition.x > windowWidth / 2 ? `-${chatWidth + gap}px` : `${fabRect.width + gap}px`;

    chatElement.style.bottom = fabPosition.y > windowHeight / 2 ? '0' : `-${chatHeight - fabRect.height}px`;

    this.showChatContent = true;
    this.cd.markForCheck();
  }

  onDragMoved(event: CdkDragMove) {
    this.lastDragEvent = event;
    this.updateChatPosition(event);
  }

  ngOnDestroy() {
    if (this.dragSubscription) {
      this.dragSubscription.unsubscribe();
    }
  }

  onDragEnd() {
    this.forceBounds();
    if (this.dragSubscription) {
      this.dragSubscription.unsubscribe();
    }
  }

  private forceBounds() {
    const dragPosition = this.lastDragEvent?.source.getFreeDragPosition();
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const fabPosition = {
      x: dragPosition ? dragPosition.x + windowWidth - 100 : windowWidth - 100,
      y: dragPosition ? dragPosition.y + windowHeight - 100 : windowHeight - 100,
    };
    if (dragPosition) {
      const fabElement = this.fabButton!.nativeElement;

      if (fabPosition.x < 0) {
        this.lastDragEvent?.source.setFreeDragPosition({ x: -windowWidth + (mobile ? 75 : 125), y: dragPosition!.y });
      }
      if (fabPosition.x + fabElement!.getBoundingClientRect().width > windowWidth) {
        this.lastDragEvent?.source.setFreeDragPosition({ x: fabElement!.getBoundingClientRect().width - (mobile ? 50 : 25), y: dragPosition!.y });
      }
      if (fabPosition.y < 0) {
        this.lastDragEvent?.source.setFreeDragPosition({ x: dragPosition!.x, y: -windowHeight + (mobile ? 125 : 100) });
      }
      if (fabPosition.y + fabElement!.getBoundingClientRect().height > windowHeight) {
        this.lastDragEvent?.source.setFreeDragPosition({ x: dragPosition!.x, y: fabElement!.getBoundingClientRect().height - 50 });
      }
    }
  }
}
