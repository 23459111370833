<div class="historique-consultation-client">
  <ng-container *ngIf="historique">
    <ion-accordion-group *ngIf="!fromCreateRdv">
      <div class="cr-item" *ngFor="let crOrRdv of historique | slice: 0 : (showVoirPlus ? 3 : historique.length + 1)">
        <ng-container *ngIf="isCompteRendu(crOrRdv)" [ngTemplateOutlet]="crTemplate" [ngTemplateOutletContext]="{ cr: crOrRdv }"></ng-container>
        <ng-container
          *ngIf="isRdvDomicile(crOrRdv)"
          [ngTemplateOutlet]="consultationContent"
          [ngTemplateOutletContext]="{ consultation: crOrRdv }"
        ></ng-container>
        <ng-container *ngIf="isHorsRdv(crOrRdv)" [ngTemplateOutlet]="horsRdvContent" [ngTemplateOutletContext]="{ horsRdv: crOrRdv }"></ng-container>
      </div>
      <div *ngIf="seeAllConsultBoolean">
        <div class="cr-item" *ngFor="let crOrRdv of historique | slice: 3 : historique.length + 1">
          <ng-container *ngIf="isCompteRendu(crOrRdv)" [ngTemplateOutlet]="crTemplate" [ngTemplateOutletContext]="{ cr: crOrRdv }"></ng-container>
          <ng-container
            *ngIf="isRdvDomicile(crOrRdv)"
            [ngTemplateOutlet]="consultationContent"
            [ngTemplateOutletContext]="{ consultation: crOrRdv }"
          ></ng-container>
          <ng-container *ngIf="isHorsRdv(crOrRdv)" [ngTemplateOutlet]="horsRdvContent" [ngTemplateOutletContext]="{ horsRdv: crOrRdv }"></ng-container>
        </div>
      </div>
    </ion-accordion-group>

    <ng-container *ngIf="fromCreateRdv">
      <ng-container *ngFor="let consultation of historique | slice: 0 : 3">
        <ng-container *ngTemplateOutlet="consultationItem; context: { consultation }"></ng-container>
      </ng-container>
      <ng-container *ngIf="seeAllConsultBoolean">
        <ng-container *ngFor="let consultation of historique | slice: 3 : historique.length + 1">
          <ng-container *ngTemplateOutlet="consultationItem; context: { consultation }"></ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
    <div class="ion-text-center" *ngIf="historique.length > 3 && showVoirPlus">
      <ion-button fill="clear" *ngIf="!seeAllConsultBoolean" (click)="seeAllConsult()">
        <ion-label class="ion-text-wrap">Voir plus</ion-label>
      </ion-button>
      <ion-button fill="clear" *ngIf="seeAllConsultBoolean" (click)="seeAllConsult()">
        <ion-label class="ion-text-wrap">Voir moins</ion-label>
      </ion-button>
    </div>
  </ng-container>
</div>
<ng-template #crTemplate let-cr="cr">
  <app-standalone-cr-view *ngIf="user" [cr]="cr" [lastVeto]="lastVeto" [user]="user" [showNomAnimal]="true" (deleted)="onDelete(cr)"></app-standalone-cr-view>
</ng-template>
<ng-template #consultationContent let-consultation="consultation">
  <app-consultation *ngIf="user" [user]="user" [consultation]="consultation"></app-consultation>
</ng-template>
<ng-template #horsRdvContent let-horsRdv="horsRdv">
  <app-horsrdv *ngIf="user" [user]="user" [horsRdv]="horsRdv" [vetos]="vetos"></app-horsrdv>
</ng-template>
<ng-template #consultationItem let-consultation="consultation">
  <ion-item
    detail
    button
    slot="header"
    color="blue200"
    lines="none"
    class="consultation-header"
    [ngClass]="consultation.rdvStep"
    [tooltip]="consultation.rdvStep | rdvStep"
    [position]="position.LEFT"
    (click)="openPreviewIfFromCreateRdv(consultation)"
  >
    <ion-label color="blue600" class="ion-text-wrap"
      ><h2>
        {{ (consultation.dateConsultation ? consultation.dateConsultation : consultation.dateSouhaitee) | date: 'mediumDate' }}
        {{ consultation.titre ? consultation.titre : consultation?.acte?.libelle }}
      </h2>
      <p *ngFor="let animal of consultation.animaux">{{ animal.nom | titlecase }}</p></ion-label
    >
  </ion-item>
</ng-template>
