<ion-header class="ion-no-border ion-no-shadow">
  <ion-toolbar color="blue200">
    <div class="header-btns">
      <ion-button fill="clear" shape="round" color="danger" (click)="cancel()" slot="start">
        <ion-icon slot="start" name="close-outline"></ion-icon>
        <ion-label>Fermer</ion-label>
      </ion-button>
      <ion-button slot="end" color="primary" shape="round" class="infos-check" (click)="download()" *ngIf="blobProps && blobProps.size">
        <ion-icon name="download-outline" slot="start"></ion-icon>
        <ion-label class="ion-text-wrap">Télécharger</ion-label>
      </ion-button>
    </div>
  </ion-toolbar>
</ion-header>
<ion-content color="blue100">
  <ion-grid>
    <ion-row>
      <ion-col>
        <ion-text color="grey900">
          <h1 *ngIf="typeProps === 'cr' || crProps">Compte-rendu</h1>
          <h1 *ngIf="typeProps === 'fichier'">
            Fichier : {{ fileProps!.fileName }}{{ fileProps?.dateDocument ? ' du ' + (fileProps!.dateDocument! | date: 'fullDate') : '' }}
          </h1>
          <h1 *ngIf="typeProps === 'facture'">Note d'honoraire {{ factureProps ? factureProps.numFacture : '' }}</h1>
          <h1 *ngIf="typeProps === 'ordo'">Ordonnance {{ ordoProps ? ordoProps.numOrdonnance : '' }}</h1>
          <p *ngIf="crProps">du {{ crProps.dateCompteRendu | date: 'fullDate' }}</p>
        </ion-text>

        <ion-card class="text-cr" *ngIf="crProps">
          <ion-text color="grey900">
            <p [innerHTML]="crProps.textCr"></p>
          </ion-text>
        </ion-card>
        <ng-container *ngIf="blobProps && blobProps.size === 0">
          <div class="ion-text-center">
            <ion-text color="danger">
              <p>Un problème est survenu avec le chargement du pdf</p>
            </ion-text>
          </div>
        </ng-container>
        <ngx-extended-pdf-viewer
          class="pdfViewer"
          *ngIf="blobProps && blobProps.size !== 0"
          [src]="blobProps"
          [height]="'75vh'"
          backgroundColor="transparent"
          [useBrowserLocale]="true"
          [mobileFriendlyZoom]="'125%'"
          [sidebarVisible]="false"
          [showToolbar]="true"
          [showSecondaryToolbarButton]="false"
          [showZoomButtons]="true"
          [showDownloadButton]="false"
          [showBookModeButton]="false"
          [showOpenFileButton]="false"
          [showPrintButton]="false"
          [showDrawEditor]="false"
          [showTextEditor]="false"
          [showStampEditor]="false"
          zoom="auto"
        ></ngx-extended-pdf-viewer>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
