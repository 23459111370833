<ion-header class="ion-no-border ion-no-shadow">
  <ion-toolbar color="blue200">
    <div class="header-btns">
      <div class="left-btns">
        <ion-button fill="clear" shape="round" color="danger" (click)="cancel()">
          <ion-icon slot="start" name="close-outline"></ion-icon>
          <ion-label>Fermer</ion-label>
        </ion-button>
      </div>
      <div class="right-btns" *ngIf="user && ([UserRole.ROLE_ADMIN, UserRole.ROLE_BACKOFFICE].includes(user.role) || user.id === horsRdv.responsable?.id)">
        <ion-button
          color="primary"
          shape="round"
          class="infos-check"
          (click)="saveRdv()"
          [disabled]="
            horsRdvForm.invalid ||
            !isEditable ||
            (newRdvStep === HorsRdvStep.CLOTURE &&
              horsRdv.type === TypeHorsRdv.ENVOI_MEDICAMENTS &&
              !(!!horsRdvForm.value.lienSuivi || (!!horsRdvForm.value.numeroSuivi && !!horsRdvForm.value.codeTransporteur)))
          "
        >
          <ion-icon name="checkmark-circle" color="success" slot="start"></ion-icon>
          <ion-label class="ion-text-wrap">Mettre à jour</ion-label>
        </ion-button>
      </div>
    </div>
  </ion-toolbar>
</ion-header>
<ion-content color="blue100">
  <form [formGroup]="horsRdvForm">
    <div class="stack">
      <div class="title">
        <div class="title-header left">
          <div class="title-info-rdv">
            <h2>
              {{ "Modification d'un" + (horsRdv.type === TypeHorsRdv.ORDONNANCE ? 'e ' : ' ') + (horsRdv.type | typeHorsRdvStr | lowercase) }}
            </h2>
          </div>
        </div>
        <div class="right formulaire">
          <ion-item class="rdv-step-select" lines="none" [ngClass]="horsRdv.step" color="blue100">
            <fieldset>
              <legend><b>Statut</b></legend>

              <ion-select
                interface="popover"
                placeholder="{{ horsRdv.step | horsRdvStep }}"
                [value]="horsRdv.step"
                class="ion-text-wrap"
                [disabled]="!rdvStepSelectionnables.length"
                (ionChange)="updateRdvState($event)"
              >
                <ion-select-option *ngFor="let step of rdvStepSelectionnables" [value]="step">{{ step | horsRdvStep }} </ion-select-option>
              </ion-select>
            </fieldset>
          </ion-item>
        </div>
      </div>
      <div class="subtitle">
        <ion-grid>
          <ion-row>
            <ion-col>
              <ion-item class="interaction-item" color="blue100" lines="none">
                <ion-label class="ion-text-wrap">
                  <h2 class="interaction">
                    <b>Intéractions :</b>
                    <ion-button slot="end" fill="clear" color="blue200" size="small" (click)="openInteraction()" id="viewInteractionRdv">
                      <ion-icon name="eye-outline" slot="icon-only" color="blue600" size="small"></ion-icon>
                    </ion-button>
                  </h2>
                  <ng-container *ngFor="let interaction of horsRdv.interactions | orderBy: 'dateEnvoi' : 'desc'">
                    <p
                      *ngIf="
                        interaction.type === TypeInteraction.CALL_CLIENT ||
                        interaction.type === TypeInteraction.DEMANDE_ANNULATION ||
                        interaction.type === TypeInteraction.DEMANDE_REPLANIFICATION
                      "
                    >
                      {{ interaction.dateEnvoi | dateRdv }} par {{ interaction.initiateur | trigramme }} : {{ interaction.contenu }}
                    </p>
                    <p *ngIf="interaction.type === TypeInteraction.DEMANDE_RDV && interaction.canal === TypeCanal.WEB">
                      {{ interaction.dateEnvoi | dateRdv }} demande WEB
                    </p>
                    <p *ngIf="interaction.type === TypeInteraction.DEMANDE_RDV && interaction.canal === TypeCanal.DIRECT">
                      {{ interaction.dateEnvoi | dateRdv }} par {{ interaction.initiateur | trigramme }} :
                      {{ interaction.initiateur.role === UserRole.ROLE_VETERINAIRE ? 'demande Veto' : 'demande ASV' }}
                    </p>
                  </ng-container>
                  <p class="no-item" *ngIf="!horsRdv.interactions">Aucune donnée disponible</p>
                </ion-label>
              </ion-item>
            </ion-col>
            <ion-col>
              <ion-card class="card-rdv">
                <ion-item color="blue200" lines="none">
                  <ion-icon name="call-outline" slot="start" color="blue600"></ion-icon>
                  <ion-label class="ion-text-wrap" color="blue900"><b>Nouveau CR d'appel</b></ion-label>
                </ion-item>
                <ion-item lines="none">
                  <fieldset>
                    <ion-input placeholder="Ajouter un nouveau CR d'appel" #newCr></ion-input>
                  </fieldset>
                </ion-item>
                <div class="ion-text-center">
                  <ion-chip (click)="addCrAppel(newCr.value); newCr.value = ''" color="primary">
                    <ion-icon name="add-circle-outline"></ion-icon>
                    <span> <ion-label>Ajouter au CR appel</ion-label></span>
                  </ion-chip>
                </div>
                <br />
              </ion-card>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
      <div class="stack">
        <div class="form-info-rdv">
          <h1 class="ion-text-center">Informations du rendez-vous</h1>

          <ion-item lines="none" *ngIf="horsRdv.type !== TypeHorsRdv.ORDONNANCE">
            <fieldset>
              <legend><b>Description</b></legend>
              <ion-input formControlName="description" placeholder="Ajouter une description"></ion-input>
            </fieldset>
          </ion-item>
          <ion-item lines="none" *ngIf="vetos">
            <fieldset>
              <legend><b>Vétérinaire responsable</b></legend>
              <ion-select placeholder="Choisir" interface="popover" formControlName="responsable">
                <ion-select-option *ngFor="let veto of vetos" [value]="veto"> Dr {{ veto.nom | titlecase }} {{ veto.prenom | titlecase }} </ion-select-option>
              </ion-select>
            </fieldset>
          </ion-item>
          <ng-container *ngIf="horsRdv.type !== TypeHorsRdv.ORDONNANCE">
            <ion-item lines="none">
              <fieldset>
                <legend><b>Adresse</b></legend>
                <ion-input placeholder="" formControlName="adresse"></ion-input>
              </fieldset>
            </ion-item>
            <ion-row>
              <ion-col size="6">
                <ion-item lines="none">
                  <fieldset>
                    <legend><b>Code Postal</b></legend>
                    <ion-input placeholder="" formControlName="codePostal" (ionChange)="getCityCode($event)"></ion-input>
                  </fieldset>
                </ion-item>
              </ion-col>
              <ion-col size="6">
                <ion-item lines="none">
                  <fieldset>
                    <legend><b>Ville</b></legend>
                    <ion-select formControlName="ville" interface="popover">
                      <div *ngIf="cityList">
                        <div *ngFor="let city of cityList">
                          <ion-select-option [value]="city">{{ city.nom }}</ion-select-option>
                        </div>
                      </div>
                    </ion-select>
                  </fieldset>
                </ion-item>
              </ion-col>
            </ion-row>
            <ion-item lines="none">
              <fieldset>
                <legend><b>Complément adresse</b></legend>
                <ion-textarea placeholder="" formControlName="detailAdresse" [rows]="1" [autoGrow]="true"></ion-textarea>
              </fieldset>
            </ion-item>
          </ng-container>
        </div>
        <br />

        <div class="card-rdv" *ngIf="horsRdv.type !== TypeHorsRdv.ORDONNANCE">
          <facturation
            *ngFor="let prestation of horsRdv.prestations"
            #facturationComponent
            [idStructure]="horsRdv.structureVeterinaire.id!"
            [finalisable]="true"
            [client]="horsRdv.client!"
            [prestation]="prestation"
            [user]="user"
            [genererFactureParAnimal]="true"
            [animaux]="horsRdv.animaux"
            [displayDeleteBtn]="false"
            [displayAddBtn]="false"
            [facturationEditable]="true"
            [displayDevisBtn]="false"
            [displayActes]="false"
            [displaySaveBtns]="false"
            [loadBlankProduit]="true"
            [onlyRenouvelables]="true"
          ></facturation>
        </div>
        <div class="card-rdv" *ngIf="horsRdv.type === TypeHorsRdv.ORDONNANCE">
          <ordonnanceComponent
            *ngFor="let prestation of horsRdv.prestations"
            #ordoComponent
            [ordoEditable]="true"
            [mailClient]="horsRdv.client!.mail"
            [animal]="prestation.animal!"
            [prestation]="prestation"
            [user]="user"
            [generateFirstOrdo]="false"
            [displaySaveBtns]="false"
            [displayDelivre]="false"
          ></ordonnanceComponent>
        </div>
        <br />
        <ion-card class="card-rdv" *ngIf="horsRdv.type !== TypeHorsRdv.ORDONNANCE">
          <ion-item color="blue400" lines="none">
            <ion-icon name="cash-outline" slot="start" color="blue600"></ion-icon>
            <ion-label class="ion-text-wrap" color="blue900"><b>Règlements</b></ion-label>
          </ion-item>

          <app-reglement
            [typeRdv]="TypeRdv.HORS_RDV"
            [idRdv]="horsRdv.id"
            [isEditable]="true"
            [(paiements)]="paiements"
            (paiementsChange)="hasUpdatedPaiements = true"
            [montant]="montant"
            [displayRefreshBtn]="false"
          ></app-reglement>
          <br />
        </ion-card>
        <ion-card class="card-rdv" *ngIf="horsRdv.type === TypeHorsRdv.ENVOI_MEDICAMENTS && newRdvStep === HorsRdvStep.CLOTURE">
          <ion-item color="blue400" lines="none">
            <ion-icon name="cube-outline" slot="start" color="blue600"></ion-icon>
            <ion-label class="ion-text-wrap" color="blue900"><b>Suivi de l'expédition</b></ion-label>
          </ion-item>
          <br />
          <ion-row>
            <ion-col size-xs="12" size-sm="12" size-md="6" size-lg="6" size-xl="6" class="rg-left">
              <ion-item lines="none">
                <fieldset>
                  <legend><b>Numéro de suivi</b></legend>
                  <ion-input formControlName="numeroSuivi" placeholder="Ajouter un numéro de suivi"></ion-input>
                </fieldset>
              </ion-item>
              <ion-item lines="none">
                <fieldset>
                  <legend><b>Transporteur</b></legend>
                  <ion-select
                    interface="popover"
                    formControlName="codeTransporteur"
                    placeholder="{{ horsRdv.suivi?.codeTransporteur }}"
                    [value]="horsRdv.suivi?.codeTransporteur"
                    class="ion-text-wrap"
                  >
                    <ion-select-option *ngFor="let code of codesTransporteur" [value]="code">{{ code }} </ion-select-option>
                  </ion-select>
                </fieldset>
              </ion-item>
            </ion-col>
            <ion-col size-xs="12" size-sm="12" size-md="6" size-lg="6" size-xl="6" class="rg-left">
              <ion-item lines="none">
                <fieldset>
                  <legend><b>Lien de suivi</b></legend>
                  <ion-input formControlName="lienSuivi" placeholder="Ajouter un lien de suivi"></ion-input>
                </fieldset>
              </ion-item>
            </ion-col>
          </ion-row>
          <br />
        </ion-card>
      </div>
    </div>
    <br />
  </form>
</ion-content>
